const NAVIGATION_OPTIONS = [
  {
    name: "Home",
    url: "/"
  },
  {
    name: "Aanbod",
    url: "/aanbod"
  },
  {
    name: "Team",
    url: "/team"
  },
  {
    name: "Werkwijze",
    url: "/werkwijze"
  },
  {
    name: "Vorming",
    url: "/vorming"
  },
  {
    name: "Contact",
    url: "/contact"
  },
  {
    name: "Links",
    url: "/links"
  }
];

export { NAVIGATION_OPTIONS };
