import { Link, graphql, useStaticQuery } from "gatsby";
import Logo from "./logo";
import { NAVIGATION_OPTIONS } from "../utils/constants";
import React from "react";

const Footer = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            address
            email
            phone
          }
        }
      }
    `
  );

  const links = NAVIGATION_OPTIONS.map(option => 
    <li className='block mb-1 w-full lg:w-1/2 xl:w-1/3' key={option.name}>
      <Link
        activeClassName='border-white italic'
        className='border-b border-solid border-transparent hover:border-white text-sm transition-border-color'
        to={option.url}
      >
        {option.name}
      </Link>
    </li>
  );

  return (
    <div className='bg-secondary px-8'>
      <div className='container sm:flex justify-between py-12 lg:pt-16 text-white'>
        <div className='flex mb-8 sm:mb-0 max-w-xs sm:max-w-full items-center flex-col w-full sm:w-1/2 md:w-1/3 lg:w-1/4'>
          <Link className='w-full' to='/'>
            <Logo inverted />
          </Link>
          <p className='mt-6 text-xs'>&copy; Praktijkhuis Intergraal 2019</p>
        </div>
        <div className='hidden md:block px-12 lg:px-16 w-1/3 lg:w-2/4'>
          <h3 className='font-bold mb-6 text-xl'>Navigatie</h3>
          <ul className='flex flex-wrap'>{links}</ul>
        </div>
        <div className='sm:text-right lg:text-left w-full sm:w-1/2 md:w-1/3 lg:w-1/4'>
          <h3 className='font-bold mb-6 text-xl'>Contacteer ons</h3>
          <div className='flex items-center mb-2 md:mb-4'>
            <div className='hidden lg:flex items-center justify-center h-5 mr-3 w-5'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 24 24'
                width='24'
                height='24'
              >
                <path
                  className='fill-current'
                  d='M5.64 16.36a9 9 0 1 1 12.72 0l-5.65 5.66a1 1 0 0 1-1.42 0l-5.65-5.66zm11.31-1.41a7 7 0 1 0-9.9 0L12 19.9l4.95-4.95zM12 14a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm0-2a2 2 0 1 0 0-4 2 2 0 0 0 0 4z'
                />
              </svg>
            </div>
            <a
              className='flex-1 text-sm hover:underline'
              href={`https://www.google.be/maps/place/${
                site.siteMetadata.address
              }/`}
              rel='noopener noreferrer'
              target='_blank'
            >
              {site.siteMetadata.address}
            </a>
          </div>
          {/* <div className='flex items-center mb-2 md:mb-4'>
            <div className='hidden lg:flex items-center justify-center h-5 mr-3 w-5'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 24 24'
                width='24'
                height='24'
              >
                <path
                  className='fill-current'
                  d='M13.04 14.69l1.07-2.14a1 1 0 0 1 1.2-.5l6 2A1 1 0 0 1 22 15v5a2 2 0 0 1-2 2h-2A16 16 0 0 1 2 6V4c0-1.1.9-2 2-2h5a1 1 0 0 1 .95.68l2 6a1 1 0 0 1-.5 1.21L9.3 10.96a10.05 10.05 0 0 0 3.73 3.73zM8.28 4H4v2a14 14 0 0 0 14 14h2v-4.28l-4.5-1.5-1.12 2.26a1 1 0 0 1-1.3.46 12.04 12.04 0 0 1-6.02-6.01 1 1 0 0 1 .46-1.3l2.26-1.14L8.28 4z'
                />
              </svg>
            </div>
            <a
              className='flex-1 text-sm hover:underline'
              href={`tel:${site.siteMetadata.phone}`}
            >
              {site.siteMetadata.phone}
            </a>
          </div> */}
          <div className='flex items-center'>
            <div className='hidden lg:flex items-center justify-center h-5 mr-3 w-5'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 24 24'
                width='24'
                height='24'
              >
                <path
                  className='fill-current'
                  d='M4 4h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6c0-1.1.9-2 2-2zm16 3.38V6H4v1.38l8 4 8-4zm0 2.24l-7.55 3.77a1 1 0 0 1-.9 0L4 9.62V18h16V9.62z'
                />
              </svg>
            </div>
            <a
              className='flex-1 text-sm hover:underline'
              href={`mailto:${site.siteMetadata.email}`}
            >
              {site.siteMetadata.email}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
