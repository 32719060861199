/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { graphql, useStaticQuery } from "gatsby";
import Helmet from "react-helmet";
import PropTypes from "prop-types";
import React from "react";
import shareImage from "../images/integraal-share.jpg";

function SEO ({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            author
            description
            keywords
            title
            url
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          content: site.siteMetadata.author,
          name: "author"
        },
        {
          content: metaDescription,
          name: "description"
        },
        {
          content: site.siteMetadata.keywords,
          property: "keywords"
        },
        {
          content: title,
          property: "og:title"
        },
        {
          content: metaDescription,
          property: "og:description"
        },
        {
          content: shareImage,
          property: "og:image"
        },
        {
          content: title,
          property: "og:site_name"
        },
        {
          content: "website",
          property: "og:type"
        },
        {
          content: site.siteMetadata.url,
          property: "og:url"
        }
      ].concat(meta)}
    />
  );
}

SEO.defaultProps = {
  description: "",
  lang: "nl-BE",
  meta: []
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired
};

export default SEO;
