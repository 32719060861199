import { Link, graphql, useStaticQuery } from "gatsby";
import Footer from "./footer";
import Logo from "./logo";
import Navigation from "./navigation";
import PropTypes from "prop-types";
import React from "react";

const Page = ({ children }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            address
          }
        }
      }
    `
  );

  return (
    <div className='bg-white flex flex-col min-h-screen'>
      <div className='bg-primary font-bold hidden md:block px-8 py-8 text-white'>
        <div className='container flex justify-center sm:justify-between'>
          <a
            className='hover:underline'
            href={`https://www.google.be/maps/place/${
              site.siteMetadata.address
            }/`}
            rel='noopener noreferrer'
            target='_blank'
          >
            {site.siteMetadata.address}
          </a>
          <Link className='hidden sm:block hover:underline' to='/contact'>
            Contacteer ons
          </Link>
        </div>
      </div>
      <div className='pb-6 md:pb-10 lg:pb-12 pt-8 md:pt-12 lg:pt-16'>
        <Link
          className='block px-8 md:px-0 mb-4 md:mb-12 mx-auto max-w-md w-full'
          to='/'
        >
          <Logo />
        </Link>
        <Navigation />
      </div>
      <div className='flex-grow animate-fade-in'>{children}</div>
      <Footer />
    </div>
  );
};

Page.propTypes = {
  children: PropTypes.node.isRequired
};

export default Page;
