import { Link } from "gatsby";
import { NAVIGATION_OPTIONS } from "../utils/constants";
import React from "react";

class Navigation extends React.Component {
  /**
   * Constructor
   */

  constructor (props) {
    super(props);

    this.state = {
      isMobileMenuOpen: false
    };

    this.toggleMenuButtonHandler = this.toggleMenuButtonHandler.bind(this);
  }

  /**
   * Handlers
   */

  toggleMenuButtonHandler () {
    this.setState({
      isMobileMenuOpen: !this.state.isMobileMenuOpen
    });
  }

  /**
   * Render
   */

  render () {
    const options = NAVIGATION_OPTIONS.map(option => 
      <Link
        activeClassName='border-white md:border-primary italic pl-1 md:pl-0'
        className='block md:inline border-b md:border-b-2 border-transparent hover:border-white md:hover:border-primary pb-1 mb-5 md:mb-0 md:mx-4 lg:mx-6 xl:mx-8 text-white md:text-black transition-border-color md:uppercase'
        key={option.name}
        onClick={this.toggleMenuButtonHandler}
        partiallyActive={option.url !== "/"}
        to={option.url}
      >
        {option.name}
      </Link>
    );

    return (
      <div>
        <button
          className='bg-secondary fixed focus:outline-none shadow-lg md:hidden p-4 right-0 top-0 text-white transition-bg z-10'
          type='button'
          onClick={this.toggleMenuButtonHandler}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 24 24'
            width='24'
            height='24'
          >
            <path
              className='fill-current'
              d='M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z'
            />
          </svg>
        </button>
        <div
          className={`bg-secondary md:bg-transparent fixed md:relative h-full max-w-xs md:max-w-full p-8 md:p-0 right-0 shadow md:shadow-none top-0 transition-transform w-full ${
            this.state.isMobileMenuOpen
              ? "-translate-x-0 z-10"
              : "-translate-x-full md:-translate-x-0"
          }`}
        >
          <div className='flex items-center justify-between md:hidden mb-10 text-white w-full'>
            <div />
            <button
              className='focus:outline-none'
              type='button'
              onClick={this.toggleMenuButtonHandler}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 24 24'
                width='24'
                height='24'
              >
                <path
                  className='fill-current'
                  d='M18.59 13H3a1 1 0 0 1 0-2h15.59l-5.3-5.3a1 1 0 1 1 1.42-1.4l7 7a1 1 0 0 1 0 1.4l-7 7a1 1 0 0 1-1.42-1.4l5.3-5.3z'
                />
              </svg>
            </button>
          </div>
          <ul className='pr-12 md:pr-0 md:flex md:justify-center'>{options}</ul>
        </div>
      </div>
    );
  }
}

export default Navigation;
